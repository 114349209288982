<template>
  <div>
    <CRow>
      <CCol class="client-create">
        <CCard class="">
          <CCardBody class="">
            <h1>設定</h1>
            <!-- <div class="company-name text-bold">会社名：{{ companyName }} 様</div> -->
            <div class="input-box">
              <div class="">
                <div class="text-bold">ユーザー認証に使用する招待コードを設定してください</div>
                <div class="input-title">招待コード</div>
                <input v-model="inviteCode" class="middle-input" type="text" :disabled="isDisabled" />
                <div class="small text-muted">・英数字を使用してください。</div>
                <div class="small text-muted">
                  ・一度決めると変更できません。変更が必要な場合はサポートまでお問い合わせください。
                </div>
                <div class="small text-muted">
                  ・桁数に決まりはありませんが、お客様に登録時に入力していただく必要があります。
                </div>
                <div class="small text-muted">・他企業様で使用されているコードは設定できません。</div>
                <div class="error-message">
                  {{ errorMessage }}
                </div>
              </div>
            </div>
            <div class="input-box">
              <div class="">
                <div class="text-bold">ユーザーアプリのタイムラインの数</div>
                <input v-model="numberOfTabs" name="numberOfTimelines" id="one" type="radio" value="1" />
                <label for="one">１つ</label>
                <input v-model="numberOfTabs" name="numberOfTimelines" id="two" type="radio" value="2" />
                <label for="two">２つ</label>
              </div>
            </div>
            <div v-if="numberOfTabs == '2'" class="input-box">
              <div class="small-input">
                <div>タブ名</div>
                <div>メイン</div>
                <input v-model="firstTabName" class="" type="text" />
              </div>
              <div class="small-input">
                <div>サブ</div>
                <input v-model="secondTabName" class="" type="text" />
              </div>
            </div>
            <CButton color="success" @click="checkClientSettings">設定完了</CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <Loading v-show="loading" />
  </div>
</template>

<script>
import firebase from "firebase";
import { setClientSettings } from "@/utils/firebase/firebase";
import { decryption } from "@/utils/crypto";
import Loading from "@/views/components/Loading";

const db = firebase.firestore();

export default {
  name: "ClientSetteing",
  components: {
    Loading,
  },
  data() {
    return {
      numberOfTabs: "1",
      firstTabName: "",
      secondTabName: "",
      inviteCode: "",
      // companyName: this.$store.state.clientUserData.clientData.companyName.name,
      errorMessage: "",
      isDisabled: false,
      clientSettingsDataId: "",
      loading: true,
    };
  },
  computed: {},
  async created() {
    console.log("client-setting created");
    const clientId = await decryption(this.$route.params.clientId);
    // const test = await setClientSettings(clientId);
    // console.log(test);
    // dashboardで一度読み込んでいるので要検討
    db.collection("clients")
      .doc(`${clientId}`)
      .collection("clientSettings")
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty == false) {
          querySnapshot.forEach((doc) => {
            // console.log(doc.id);
            this.inviteCode = doc.data().inviteCode;
            this.isDisabled = true;
            this.clientSettingsDataId = doc.id;
            this.numberOfTabs = doc.data().numberOfTabs;
            this.firstTabName = doc.data().firstTabName;
            this.secondTabName = doc.data().secondTabName;
          });
        }
      });
    this.loading = false;
  },
  methods: {
    async checkClientSettings() {
      await db
        .collectionGroup("clientSettings")
        .where("inviteCode", "==", this.inviteCode)
        .get()
        .then(async (querySnapshot) => {
          // console.log(querySnapshot);
          if (querySnapshot.empty == false && this.isDisabled == false) {
            this.errorMessage = "既に使われている招待コードです。";
          } else {
            await this.addClientSettings();
            this.$router.push("/dashboard");
          }
        });
    },
    async addClientSettings() {
      if (this.isDisabled == false) {
        await db
          .collection("clients")
          .doc(`${this.$store.state.clientUserData.clientData.clientID}`)
          .collection("clientSettings")
          .add({
            numberOfTabs: this.numberOfTabs,
            firstTabName: this.firstTabName,
            secondTabName: this.secondTabName,
            inviteCode: this.inviteCode,
          });
        db.collection("clients").doc(`${this.$store.state.clientUserData.clientData.clientID}`).set(
          {
            inviteCode: this.inviteCode,
          },
          { merge: true }
        );
      } else {
        await db
          .collection("clients")
          .doc(`${this.$store.state.clientUserData.clientData.clientID}`)
          .collection("clientSettings")
          .doc(this.clientSettingsDataId)
          .update({
            numberOfTabs: this.numberOfTabs,
            firstTabName: this.firstTabName,
            secondTabName: this.secondTabName,
            inviteCode: this.inviteCode,
          });
        db.collection("clients").doc(`${this.$store.state.clientUserData.clientData.clientID}`).set(
          {
            inviteCode: this.inviteCode,
          },
          { merge: true }
        );
      }
    },
  },
};
</script>
<style scoped>
h1 {
  margin-bottom: 40px;
}
.name {
  display: flex;
}

input {
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #768192;
}
input::placeholder {
  color: #bbb;
}

input[type="radio"] {
  margin: 5px;
}
.input-title {
  margin-top: 10px;
}
label {
  margin-right: 2rem;
  margin-bottom: 0px;
}

.small-input {
  margin-right: 1rem;
}

.input-box {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: flex-end;
}

.middle-input {
  width: 300px;
}
.course select {
  width: 300px;
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #3c4b64;
}
.error-message {
  color: red;
  height: 20px;
  font-weight: bold;
}

.company-name {
  padding-bottom: 10px;
}
</style>
